

import Page from './components/Page';
import WebViewer, { Core }  from '@pdftron/webviewer';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import { UploadMultiFile, UploadSingleFile } from './components/upload'
import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useLocation } from 'react-router';
import { DialogAnimate } from './components/animate';
import getVariant from './components/Variant';


// ----------------------------------------------------------------------

const gloablInstance = {current: undefined};

const PdfTronComponent = ({ filePath, setShowInputModel, scaleValue, isContinue }) => {
  const { pathname } = useLocation();  
  const viewerRef = useRef(null);
  const cropRef = useRef(null);
  if(gloablInstance.current){
    const { Annotations, Tools, documentViewer, annotationManager   } = gloablInstance.current.Core;
    //set the default unit
    const measurementTool = documentViewer.getTool('AnnotationCreateDistanceMeasurement'); 
    measurementTool.defaults.Scale =  [[1, 'mm'], [scaleValue, 'm']];

    const globalDistanceMeasurementTool = documentViewer.getTool('AnnotationCreateDistanceMeasurement');        
    globalDistanceMeasurementTool.setStyles(() => ({
      // value of Scale is an array that is consisted of two arrays
      // the first element in each array is the scale ratio and the second element is the unit.
      // valid units are: mm, cm, m, km, mi, yd, ft, in and pt
      // the following array means that for the annotations created by the distance measurement tool, 0.25 inches on the document is equal to 1 inch in the real world
      Scale: [[1, 'mm'], [scaleValue, 'm']],
      // value of Precision is a number that means how many decimal places the calculated value should have
      Precision: 0.001
    }));

    const globalRectToolMeasurement = documentViewer.getTool('AnnotationCreateRectangularAreaMeasurement');
    globalRectToolMeasurement.setStyles(() =>  ({               
      StrokeColor: new Annotations.Color(228, 66, 52),
      Opacity: 0.5,
      FillColor: new Annotations.Color(197, 68, 100),
      Scale: [[1, 'mm'], [scaleValue, 'm']],
      Precision: 0.001
    }));

    const globalEcllipseToolMeasurement = documentViewer.getTool('AnnotationCreateAreaMeasurement');
    globalEcllipseToolMeasurement.setStyles(() =>  ({               
      StrokeColor: new Annotations.Color(228, 66, 52),
      Opacity: 0.5,
      FillColor: new Annotations.Color(197, 68, 10),
      Scale: [[1, 'mm'], [scaleValue, 'm']],
      Precision: 0.001
    }));


    const globalAnnotations = annotationManager.getAnnotationsList();
    globalAnnotations.forEach(anno => {
      anno.Scale = [[1, 'mm'], [scaleValue, 'm']];
      annotationManager.updateAnnotation(anno);
      documentViewer.refreshAll();      
      console.log("Applied the scale: " + [[1, 'mm'], [scaleValue, 'm']] + " to " + anno.Subject);
    });    
  }

  useEffect(() => {    
    WebViewer({path: '/webviewer/lib', initialDoc: `${filePath}`, enableMeasurement: true}, viewerRef.current).then(instance => {
      console.log("instance", instance);
      instance.UI.setMeasurementUnits({
        from: ['mm'],
        to: ['m']
      });
      
      gloablInstance.current = instance;
      const { Annotations, Tools, documentViewer   } = instance.Core;
      const { annotationManager } = instance.Core;
      
      annotationManager.setCurrentUser("Demo Builder");
      annotationManager.promoteUserToAdmin();
      const distanceMeasurementTool = documentViewer.getTool('AnnotationCreateDistanceMeasurement');  
      
      distanceMeasurementTool.setStyles(() => ({
        // value of Scale is an array that is consisted of two arrays
        // the first element in each array is the scale ratio and the second element is the unit.
        // valid units are: mm, cm, m, km, mi, yd, ft, in and pt
        // the following array means that for the annotations created by the distance measurement tool, 0.25 inches on the document is equal to 1 inch in the real world
        Scale: [[1, 'mm'], [1, 'm']],
        // value of Precision is a number that means how many decimal places the calculated value should have
        Precision: 0.001
      }));      

      // console.log(distanceMeasurementTool.defaults.Scale, "Scale initial value");
      // distanceMeasurementTool.defaults.Scale =  [[1, 'mm'], [refScaleValue.current, 'm']];
      // console.log(distanceMeasurementTool.defaults.Scale, "After applying scale");

      const rectToolMeasurement = documentViewer.getTool('AnnotationCreateRectangularAreaMeasurement');
      rectToolMeasurement.setStyles(() =>  ({               
        StrokeColor: new Annotations.Color(228, 66, 52),
        Opacity: 0.5,
        FillColor: new Annotations.Color(197, 68, 100),
        Scale: [[1, 'mm'], [1, 'm']],
        Precision: 0.001
      }));

      const ecllipseToolMeasurement = documentViewer.getTool('AnnotationCreateAreaMeasurement');
      ecllipseToolMeasurement.setStyles(() =>  ({               
        StrokeColor: new Annotations.Color(228, 66, 52),
        Opacity: 0.5,
        FillColor: new Annotations.Color(197, 68, 10),
        Scale: [[1, 'mm'], [1, 'm']],
        Precision: 0.001
      }));


      annotationManager && annotationManager.addEventListener('annotationChanged', (annotations, action) => {        
        console.log(action, "action");        
        annotations.forEach(annotation => {
          console.log(annotation, "changed-annotation");
          console.log(annotation.Zaa, "New scale of annotation");
          if((action === 'add' || action === 'modify') && annotation.Subject === "Line"){      
            const annotations = annotationManager.getAnnotationsList();
            annotations.forEach(anno => {
              anno.Scale = annotation.Scale;
              console.log(annotation.BL, "BL Value");
              annotationManager.updateAnnotation(anno);
              documentViewer.refreshAll();
              setShowInputModel({action: true, value: annotation.kt});
            });
          }
          else if(action === 'add' && annotation.Subject === "Rectangle" && annotation.ToolName === "CropPage"){
            //instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
            cropRef.current = "fitwidth";
          }
        });
      });

      documentViewer && documentViewer.addEventListener('layoutChanged', (e) => {
        if(cropRef.current && cropRef.current === "fitwidth"){
          instance.UI.setFitMode(instance.UI.FitMode.FitWidth);
        }        
      });

      documentViewer && documentViewer.addEventListener('annotationsLoaded', () => {
        // all annotations are available
        const annotations = annotationManager.getAnnotationsList();
        console.log("Anotation loaded", annotations);
        const data = [[[129, 380], [119, 380], [119, 467], [37, 467], [37, 591], [175, 591], [175, 460], [149, 460], [175, 460], [175, 380], [129, 380]], [[39, 380], [39, 464], [114, 464], [114, 380], [45, 380]], [[226, 285], [220, 285], [220, 321], [234, 321], [234, 285]], [[212, 202], [205, 202], [205, 277], [307, 277], [307, 202], [212, 202]], [[208, 161], [208, 194], [225, 194], [225, 161]], [[290, 160], [283, 160], [283, 196], [311, 196], [311, 226], [332, 226], [314, 226], [314, 277], [450, 277], [450, 160], [290, 160]], [[212, 117], [212, 155], [230, 194], [281, 194], [281, 158], [265, 117], [212, 117]], [[102, 99], [37, 99], [37, 374], [137, 374], [137, 352], [137, 373], [180, 373], [180, 351], [180, 514], [234, 514], [234, 325], [214, 281], [203, 281], [203, 99], [102, 99]], [[275, 31], [275, 85], [295, 85], [275, 85], [275, 153], [407, 153], [407, 31], [293, 31]], [[260, 30], [208, 30], [208, 93], [224, 104], [224, 111], [269, 111], [269, 30]]];
        console.log(data.length, "datalength");
        console.log(data, "data");
        // const annotationToDraw = [];
        // const adjustmentPixel = 25;
        // for(let i=0; i < data.length; i++){
        //   let firstPoint = data[i][0]
        //   for(let j=1; j < data[i].length; j++){
        //     let secondPoint = data[i][j];
        //     console.log(firstPoint, "FirstPoint");
        //     console.log(secondPoint, "SecondPoint");
        //     const lineAnnotation = new Annotations.LineAnnotation();
        //     lineAnnotation.setStartPoint(firstPoint[0], firstPoint[1]);
        //     lineAnnotation.setEndPoint(secondPoint[0], secondPoint[1]);
        //     lineAnnotation.StrokeColor = new Annotations.Color(255, 100, 144, 1);
        //     lineAnnotation.StrokeThickness = 5;
        //     lineAnnotation.ReadOnly = true;
        //     lineAnnotation.Printable = false;
        //     lineAnnotation.Listable = false;
        //     annotationToDraw.push(lineAnnotation);
        //     firstPoint = secondPoint;
        //   }
        // }

        // console.log(annotationToDraw, "AnnotationToDraw");
        // annotationManager.addAnnotations(annotationToDraw);
        // annotationManager.drawAnnotationsFromList(annotationToDraw);

      });
      class TriangleAnnotation extends Annotations.CustomAnnotation {
        constructor() {
          super('triangle'); // provide the custom XFDF element name
          this.Subject = 'Triangle';
        }

        draw(ctx, pageMatrix){
          // the setStyles function is a function on markup annotations that sets up
          // certain properties for us on the canvas for the annotation's stroke thickness.
          this.setStyles(ctx, pageMatrix);
          // first we need to translate to the annotation's x/y coordinates so that it's
          // drawn in the correct location
          ctx.translate(this.X, this.Y);
          ctx.beginPath();
          ctx.moveTo(this.Width / 2, 0);
          ctx.lineTo(this.Width, this.Height);
          ctx.lineTo(0, this.Height);
          ctx.closePath();
          ctx.fill();
          ctx.stroke();

        }
      }

      // this is necessary to set the elementName before instantiation
      TriangleAnnotation.prototype.elementName = 'triangle';

      annotationManager.registerAnnotationType(TriangleAnnotation.prototype.elementName, TriangleAnnotation);

      class TriangleCreateTool extends Tools.GenericAnnotationCreateTool {
        constructor(documentViewer) {
          // TriangleAnnotation is the class (function) for our annotation we defined previously
          super(documentViewer, TriangleAnnotation);
        }
      };

      const triangleToolName = 'AnnotationCreateTriangle';
      const triangleTool = new TriangleCreateTool(documentViewer);
      instance.UI.registerTool({
        toolName: triangleToolName,
        toolObject: triangleTool,
        buttonImage: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
          '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
          '<path fill="none" d="M0 0h24v24H0V0z"/>' +
        '</svg>',
        buttonName: 'triangleToolButton',
        tooltip: 'Triangle'
      }, TriangleAnnotation);

      const saveAnnotation = () => {
        annotationManager.exportAnnotations({links: true, widgets: true}).then(xfdfString => {
          console.log(xfdfString);
          localStorage.setItem('annotation', xfdfString);
          alert("Saved..");
        });
      }

      const loadAnnotation = () => {
        const xfdfString = localStorage.getItem('annotation') ?? "";
        console.log(xfdfString);
        annotationManager.importAnnotations(xfdfString);
      }

      const download = () => {
        instance.UI.downloadPdf({includeAnnotations: true, flatten: true});
      }

      const loadAIData = (props) => {
        console.log("loadAIData clicked");
        const doc = instance.Core.documentViewer.getDocument();
        const pageNumber = 1;
        doc.loadCanvasAsync(({
          pageNumber,
          zoom: 2, // render at twice the resolution
          drawComplete: async (thumbnail) => {
            // optionally comment out "drawAnnotations" below to exclude annotations
            await instance.Core.documentViewer.getAnnotationManager().drawAnnotations(pageNumber, thumbnail);
            const dataURL = thumbnail.toDataURL();
            var blobBin = atob(dataURL.split(',')[1]);
            var array = [];
            for(var i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
            }
            var file=new Blob([new Uint8Array(array)], {type: 'image/png'});
            const formdata = new FormData();
            var newFile = new File([file], "image.png");
            //const formdata = new FormData();
            formdata.append("file", newFile);
            formdata.append("PixelLength", 11.19); //mm to pixel ratio
            formdata.append("yPDFPageExtent", doc.getPageInfo(1).height); // xfdf height ()
            formdata.append("yApiPageExtent", thumbnail.height); // converted png hight

            //fetch({url: 'https://bunningsbuildproxfdfservice.azurewebsites.net/api/getxfdf', method: 'POST', body: formdata})
            
            // PixelLength -  e.g. 11.19
            // yPDFPageExtent - e.g. 1193.0
            // yApiPageExtent - e.g. 4768.0

            // const response  = await fetch('https://bunningsbuildproxfdfservice.azurewebsites.net/api/getxfdf', {
            //   method: "POST",
            //   body: formdata
            // });  
            
            // const body = await response.text();
            // console.log(body, "Body");

            fetch("https://bunningsbuildproxfdfservice.azurewebsites.net/api/getxfdf", {              
              method: 'POST', 
              body: formdata
            })
            .then(response => response.text().then(xfdfString => 
            {
              console.log(xfdfString, "XFDFString");
              annotationManager.importAnnotations(xfdfString);
            }))            
            .catch(err => {
              console.log(err);
              alert(err);
            }
            )
          }
        }));
      }

      // instance.UI.annotationPopup.add({
      //   type: 'actionButton',
      //   label: 'test',
      //   onClick: () => console.log('clicked')
      // });

      instance.UI.setHeaderItems((header) => {
        header.getHeader('toolbarGroup-Shapes').get('freeHandToolGroupButton').insertBefore({
          type: 'toolButton',
          toolName: triangleToolName
        });                    
      });
      
      instance.UI.setHeaderItems(header => {
        // header.push({
        //   type: 'actionButton',
        //   img: '/icons/load.svg',
        //   onClick: loadAnnotation,
        //   title: 'Load pdf',
        //   dataElement: 'alertButton',
        //   hidden: [ 'mobile' ]
        // });
      //   header.push(
      //     {
      //       type: 'actionButton',
      //       img: '/icons/save.svg',
      //       onClick: saveAnnotation,
      //       title: 'Save pdf',
      //       dataElement: 'alertButton',
      //       hidden: [ 'mobile' ]
      //     }
      //    );
      //    header.push(
      //     {
      //       type: 'actionButton',
      //       img: '/icons/download.svg',
      //       onClick: download,
      //       title: 'Download pdf',
      //       dataElement: 'alertButton',
      //       hidden: [ 'mobile' ]
      //     }
      //    );
         header.push(
          {
            type: 'actionButton',
            img: '/icons/navigate.svg',
            onClick: loadAIData,
            title: 'Load AI',
            dataElement: 'alertButton',
            hidden: [ 'mobile' ]
          }
         )  
        }
      );




      documentViewer && documentViewer.addEventListener('documentLoaded', () => {
        // set the tool mode to our tool so that we can start using it right away
        // set the tool button crop as default
        // url: https://www.pdftron.com/documentation/web/guides/hiding-elements/
        //instance.UI.setToolMode('distanceToolButton');
        instance.UI.disableTools([Tools.ToolNames.UNDERLINE, Tools.ToolNames.UNDERLINE2, Tools.ToolNames.UNDERLINE3, Tools.ToolNames.UNDERLINE4]);
        
      });


      instance.UI.disableElements(['toolbarGroup-Edit']);
      instance.UI.disableElements(['toolbarGroup-Insert']);
      instance.UI.disableElements(['toolbarGroup-Shapes']);
      instance.UI.disableElements(['toolbarGroup-Forms', 'toolbarGroup-FillAndSign']);          
      instance.UI.setToolbarGroup('toolbarGroup-Measure');
      instance.UI.disableElements(['underlineToolGroupButton', 'strikeoutToolGroupButton', 'squigglyToolGroupButton', 'shapeToolGroupButton', 'freeHandToolGroupButton', 'freeHandHighlightToolGroupButton', 'toolsOverlay', 'cloudyRectangleAreaToolGroupButton']);


    });
    }, []);
    return(
      <div style={{height: "85vh"}} className="viewer" ref={viewerRef}></div>
    );
}

const ScaleAlertInfo = ({isOpen, onOpen, onClose, selectVariant, setIsContinue, ...other}) => {  
  return(
    <DialogAnimate open={isOpen} onClose={onClose} animate={getVariant(selectVariant)}>
        <DialogTitle id="alert-dialog-title">Calibrate for real-world measurements</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Box component="span"><b> Step 1. </b> Click and drag a line over the longest known dimension. </Box> <br />
              <Box component="span"><b> Step 2. </b> Enter the real-world length of the line you've drawn </Box> <br />                             
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => { setIsContinue(true); onClose()}}>
            Continue
          </Button>
          <Button onClick={() => {setIsContinue(false); onClose()}} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </DialogAnimate>
  );
}

const ScaleInputAlertInfo = ({isOpen, onOpen, onClose, selectVariant, onSetValue, originalValue, ...other}) => { 
  const scaleV = useRef(null);
  const calculateScale = (e) => {
    const dpi = 72.0;
    const mmToInchRatio = 25.4;
    const mmToPixelRatio = 0.3527777777777777; //mmToInchRatio / dpi;
    const Kt = originalValue;
    const metersInput =  Number.parseFloat(e.target.value);;
    const drawLineLengthInXfdfUnits = Kt; // (drawLineLengthInMMUnits * pixelToMmRatio)
    const drawLineLengthInMMUnits = (drawLineLengthInXfdfUnits * mmToPixelRatio);
    const measurementRatioPerMm = metersInput / drawLineLengthInMMUnits; // 0.12446735809615693m;

    // const mmToPixelRatio = 0.3527777777777777;
    // const inputValue = Number.parseFloat(e.target.value);
   
    // const drawLineLengthInMMUnits = Number.parseFloat(originalValue);
    // const scaleValue = (inputValue / (drawLineLengthInMMUnits * mmToPixelRatio))
    console.log({inputValue: metersInput, xfdfLength: Kt, newValue: measurementRatioPerMm }, "params to calculate the scale");
    scaleV.current = measurementRatioPerMm;
  } 
  return(
    <DialogAnimate open={isOpen} onClose={onClose} animate={getVariant(selectVariant)}>
        <DialogTitle id="alert-dialog-title">Calibrate for real-world measurements</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <Box component="span"><b> Step 1. </b> Click and drag a line over the longest known dimension. <img src="/icons/green-tick.png" alt="tick" height="20px" width="20px"/> </Box> <br />
              <Box component="span"><b> Step 2. </b> Enter the real-world length of the line you've drawn </Box> <br />
              <Box component="span"><input type="text" onChange={(e) => calculateScale(e)} placeholder={`length with units. i.e 10 m`}></input> </Box> <br />
              <Box component="span"><i><small>Current value found: {originalValue}</small></i></Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={(e) =>  {onSetValue(scaleV.current); onClose()}}>
            Continue
          </Button>
          <Button onClick={onClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </DialogAnimate>
  );
}

export default function Estimates() {
    const [preview, setPreview] = useState(false);
    const [isFileUploadDone, setIsFileUploadDone] = useState(false);
    const [files, setFiles] = useState([]);
    const [file, setFile] = useState(null);
    const [infoModal, setInfoModal] = useState(false);
    const [infoInputModal, setInfoInputModal] = useState({action: false, value: ''});
    const [scaleValue, setScaleValue] = useState(1);
    const [isContinue, setIsContinue] = useState(false);

    const handleDropSingleFile = useCallback((acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        setFile({
          ...file,
          preview: URL.createObjectURL(file)
        });
        //uploadFile();
      }
    }, []);

    const handleDropMultiFile = useCallback(
      (acceptedFiles) => {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          )
        );
      },
      [setFiles]
    );

    const handleRemoveAll = () => {
      setFiles([]);
    };
  
    const handleRemove = (file) => {
      const filteredItems = files.filter((_file) => _file !== file);
      setFiles(filteredItems);
    };

    const uploadFiles = () => {
      setIsFileUploadDone(true);
      console.log(files, "files to upload");
    }
  
    //             <UploadMultiFile onUploadFiles = {e => uploadFiles()} showPreview={preview}
    //             files={files}
    //             onDrop={handleDropMultiFile}
    //             onRemove={handleRemove}
    //             onRemoveAll={handleRemoveAll} sx = {{height: "90%"}}/>
    //             <UploadSingleFile file={file} onDrop={handleDropSingleFile} />
        
  return (
    <>
      { isFileUploadDone ? 
        <> 
          <Button onClick = {() => setIsFileUploadDone(false)}>Start over</Button> 
          <Button onClick = {() => setInfoModal(true)}>Measurement Scale</Button> 
          {infoModal ? <ScaleAlertInfo onOpen={() => setInfoModal(false)} onClose={() => setInfoModal(false)} selectVariant={"slideInUp"} isOpen = {infoModal} setIsContinue={setIsContinue}></ScaleAlertInfo> : null }
          {!infoModal && infoInputModal.value ? <ScaleInputAlertInfo onSetValue = {setScaleValue} onOpen={() => setInfoInputModal({action: false, value: ''})} onClose={() => setInfoInputModal({action: false, value: ''})} selectVariant={"slideInUp"} isOpen = {infoInputModal.action} originalValue = {infoInputModal.value}></ScaleInputAlertInfo> : null}
        </>: null }
      {
        isFileUploadDone ? <PdfTronComponent isContinue={isContinue} scaleValue={scaleValue}  filePath={files[0].preview} setShowInputModel={setInfoInputModal}></PdfTronComponent>  : <UploadMultiFile onUploadFiles = {e => uploadFiles()} showPreview={preview}
                    files={files}
                     onDrop={handleDropMultiFile}
                     onRemove={handleRemove}
                    onRemoveAll={handleRemoveAll} sx = {{height: "90%"}}/>
      }
    </>
  );
}
