import React, {useEffect, useRef} from 'react'
import WebViewer, { Core }  from '@pdftron/webviewer';
import './App.css'
import { useNavigate  } from "react-router-dom";

function Layer() {
  const viewerRef = useRef(null);
  const history = useNavigate();
    useEffect(() => {
      WebViewer({path: '/webviewer/lib', initialDoc: '/pdfs/construction_drawing-final.pdf', enableMeasurement: true}, viewerRef.current).then(instance => {
        console.log("instance", instance);
        instance.UI.setMeasurementUnits({
          from: ['mm'],
          to: ['m']
        });
        

        const { Annotations, Tools, documentViewer   } = instance.Core;
        const { annotationManager } = instance.Core;
        
        annotationManager.setCurrentUser("Demo Builder");
        const distanceMeasurementTool = documentViewer.getTool('AnnotationCreateDistanceMeasurement');      
        distanceMeasurementTool.setStyles(() => ({
          // value of Scale is an array that is consisted of two arrays
          // the first element in each array is the scale ratio and the second element is the unit.
          // valid units are: mm, cm, m, km, mi, yd, ft, in and pt
          // the following array means that for the annotations created by the distance measurement tool, 0.25 inches on the document is equal to 1 inch in the real world
          Scale: [[1, 'mm'], [1, 'm']],
          // value of Precision is a number that means how many decimal places the calculated value should have
          Precision: 0.001
        }));

        //show left panel
        instance.UI.openElements([ 'menuOverlay', 'leftPanel' ]);

        //show layers panel
        instance.UI.setActiveLeftPanel("layersPanel");

        const rectToolMeasurement = documentViewer.getTool('AnnotationCreateRectangularAreaMeasurement');
        rectToolMeasurement.setStyles(() =>  ({               
          StrokeColor: new Annotations.Color(228, 66, 52),
          Opacity: 0.5,
          FillColor: new Annotations.Color(197, 68, 100),
          Scale: [[1, 'mm'], [1, 'm']],
          Precision: 0.001
        }));

        const ecllipseToolMeasurement = documentViewer.getTool('AnnotationCreateAreaMeasurement');
        ecllipseToolMeasurement.setStyles(() =>  ({               
          StrokeColor: new Annotations.Color(228, 66, 52),
          Opacity: 0.5,
          FillColor: new Annotations.Color(197, 68, 10),
          Scale: [[1, 'mm'], [1, 'm']],
          Precision: 0.001
        }));


        annotationManager.addEventListener('annotationChanged', (annotations, action) => {
          console.log("annotation - changed", annotations);
          console.log("action", action);
          if (action === 'add') {
            annotations.forEach(annotation => {
              annotation.Rotation = 120;
              console.log("Rotation check", annotation);              
              console.log("Rotation enabled..");          
            });
          }

          annotations.forEach(annotation => {
            if(annotation.Subject  === "Rectangle"){
              console.log("rect found", annotation)
              console.log("Area", {x: annotation.X, y: annotation.Y, height: annotation.Height, width: annotation.Width});
            }
          });
        });
        documentViewer.addEventListener('annotationsLoaded', () => {
          // all annotations are available
          const annotations = annotationManager.getAnnotationsList();
          console.log("Anotation loaded", annotations);
        });
        class TriangleAnnotation extends Annotations.CustomAnnotation {
          constructor() {
            super('triangle'); // provide the custom XFDF element name
            this.Subject = 'Triangle';
          }
  
          draw(ctx, pageMatrix){
            // the setStyles function is a function on markup annotations that sets up
            // certain properties for us on the canvas for the annotation's stroke thickness.
            this.setStyles(ctx, pageMatrix);
            // first we need to translate to the annotation's x/y coordinates so that it's
            // drawn in the correct location
            ctx.translate(this.X, this.Y);
            ctx.beginPath();
            ctx.moveTo(this.Width / 2, 0);
            ctx.lineTo(this.Width, this.Height);
            ctx.lineTo(0, this.Height);
            ctx.closePath();
            ctx.fill();
            ctx.stroke();
  
          }
        }
  
        // this is necessary to set the elementName before instantiation
        TriangleAnnotation.prototype.elementName = 'triangle';
  
        annotationManager.registerAnnotationType(TriangleAnnotation.prototype.elementName, TriangleAnnotation);
  
        class TriangleCreateTool extends Tools.GenericAnnotationCreateTool {
          constructor(documentViewer) {
            // TriangleAnnotation is the class (function) for our annotation we defined previously
            super(documentViewer, TriangleAnnotation);
          }
        };
  
        const triangleToolName = 'AnnotationCreateTriangle';
        const triangleTool = new TriangleCreateTool(documentViewer);
        instance.UI.registerTool({
          toolName: triangleToolName,
          toolObject: triangleTool,
          buttonImage: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">' +
            '<path d="M12 7.77L18.39 18H5.61L12 7.77M12 4L2 20h20L12 4z"/>' +
            '<path fill="none" d="M0 0h24v24H0V0z"/>' +
          '</svg>',
          buttonName: 'triangleToolButton',
          tooltip: 'Triangle'
        }, TriangleAnnotation);

        const saveAnnotation = () => {
          annotationManager.exportAnnotations({links: false, widgets: false}).then(xfdfString => {
            console.log(xfdfString);
            localStorage.setItem('annotation', xfdfString);
            alert("Saved..");
          });
        }

        const loadAnnotation = () => {
          const xfdfString = localStorage.getItem('annotation') ?? "";
          console.log(xfdfString);
          annotationManager.importAnnotations(xfdfString);
        }

        const download = () => {
          instance.UI.downloadPdf({includeAnnotations: true, flatten: true});
        }
        const navigate = (props) => {
            history('/')
        }

        instance.UI.annotationPopup.add({
          type: 'actionButton',
          label: 'test',
          onClick: () => console.log('clicked')
        });
  
        instance.UI.setHeaderItems((header) => {
          header.getHeader('toolbarGroup-Shapes').get('freeHandToolGroupButton').insertBefore({
            type: 'toolButton',
            toolName: triangleToolName
          });                    
        });
        instance.UI.setHeaderItems(header => {
          header.push({
            type: 'actionButton',
            img: '/icons/load.svg',
            onClick: loadAnnotation,
            title: 'Load pdf',
            dataElement: 'alertButton',
            hidden: [ 'mobile' ]
          });
          header.push(
            {
              type: 'actionButton',
              img: '/icons/save.svg',
              onClick: saveAnnotation,
              title: 'Save pdf',
              dataElement: 'alertButton',
              hidden: [ 'mobile' ]
            }
           );
           header.push(
            {
              type: 'actionButton',
              img: '/icons/download.svg',
              onClick: download,
              title: 'Download pdf',
              dataElement: 'alertButton',
              hidden: [ 'mobile' ]
            }
           );
           header.push(
            {
              type: 'actionButton',
              img: '/icons/navigate.svg',
              onClick: navigate,
              title: 'Navigate',
              dataElement: 'alertButton',
              hidden: [ 'mobile' ]
            }
           )  
          }
        );



  
        documentViewer.addEventListener('documentLoaded', () => {
          // set the tool mode to our tool so that we can start using it right away
          instance.UI.setToolMode(triangleToolName);
        });
  
  
        instance.UI.disableElements(['toolbarGroup-Edit']);
        instance.UI.disableElements(['toolbarGroup-Insert']);
        instance.UI.disableElements(['toolbarGroup-Annotate']);
        instance.UI.disableElements(['toolbarGroup-Forms', 'toolbarGroup-FillAndSign']);
  
      });
      }, [])

    return (
        <div>
            <div className="viewer" ref={viewerRef}></div>
        </div>
    )
}

export default Layer;
