import React from 'react'
import { BrowserRouter, Route, Routes, Redirect } from 'react-router-dom';
import App from './App';
import Estimates from './Estimates';
import Layer from './Layer';

const NoMatch = () => (
    <>
        <p>Sorry not found</p> <br />
        <a href="/">Back to home</a>
    </>
  );

  
export default function AppRoute() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Estimates />}></Route>
                <Route path="/pdftron/layer" element={<Layer />}></Route>
                <Route path="/pdftron/basic" element={<App />}></Route>
                <Route path="*" element={<NoMatch />} />   
            </Routes>
        </BrowserRouter>
    )
}
